<template>
  <div class="rating-box">
    <div class="rating" :style="{ width: width ? width + '%' : '100%' }"></div>
  </div>
</template>

<script>
export default {
  name: "Rating",
  props: ["width"]
};
</script>

<style scoped>
.rating-box {
  color: #f68127;
}
.rating-box:before {
  content: "\f006 \f006 \f006 \f006 \f006";
}
.rating-box .rating {
  color: #f68127;
}
.rating-box .rating:before {
  content: "\f005 \f005 \f005 \f005 \f005";
}
</style>
