<template>
  <div>
    <div class="content-header no-bg-01">
      <div class="title-1">
        <router-link to="/"><img src="../assets/back_arrow.svg"/></router-link>
      </div>
    </div>
    <div class="single-games" v-if="bodyContainer">
      <div class="gd-top">
        <div class="gd-top-sec-02">
          <div class="gd-thumb">
            <!--            <img :src="host + gameDetails.thumbnail_path" />-->
            <img :src="pathName(gameDetails.thumbnail_path)" />
          </div>
          <div class="gd-description">
            <h2>{{ gameDetails.title }}</h2>
            <h3>{{ gameDetails.long_description.substr(0, 20) }}...</h3>
          </div>
        </div>
        <div class="des-rag">
          <div class="pl-btn-1">
            <router-link
              :to="{
                name: 'GamePlay',
                params: { gameId: $route.params.gameId }
              }"
              tag="button"
              class="pl-btn-2"
            >
              Play
            </router-link>
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="pl-btn-2"-->
            <!--              @click="startGame(gameDetails.link)"-->
            <!--              id="startGame"-->
            <!--            >-->
            <!--              Play-->
            <!--            </button>-->
          </div>
          <div class="gd-coins">
            <img src="../assets/gold-coins-icon.svg" />
            {{ gameDetails.is_paid_game ? gameDetails.charge_coin : "Free" }}
          </div>
          <div class="gd-share">
            <button type="button" onclick="showModal('shareModal')">
              <img src="../assets/share-icon.svg" />
              <!--              <vue-yandex-share v-bind="options"></vue-yandex-share>-->
            </button>
          </div>
        </div>

        <div class="total-count">
          <div class="count-1">
            <h2>Type</h2>
            <h3>Single</h3>
          </div>
          <div class="count-2">
            <h2>{{ gameDetails.count }}</h2>
            <h3>Players</h3>
          </div>
          <div class="count-3">
            <h2>#20</h2>
            <h3>Hyper Casual</h3>
          </div>
        </div>
      </div>

      <div class="streaming-gd-01" v-if="gameResources.length">
        <ul>
          <li v-if="videoResources.length">
            <a class="wid-2" @click="video()">
              <div class="play-btn-gd">
                <img src="../assets/play-icon.svg" />
              </div>
              <!--              <img src="../assets/gd-video.png" />-->
              <img :src="pathName(videoResources[0].thumbnail_path)" />
            </a>
          </li>
          <li v-for="gameResource in gameResources" :key="gameResource.id">
            <a class="wid-2">
              <img :src="pathName(gameResource.thumbnail_path)" />
            </a>
          </li>
        </ul>
      </div>

      <div class="gd-detail-01">
        <p>
          {{ gameDetails.long_description }}<br />{{ gameDetails.conditions }}
        </p>
        <!--                <p>{{ gameDetails.conditions }}</p>-->
      </div>

      <div class="game-review">
        <div class="rv-tal-01">
          <div class="review-titles-2"><h3>Review</h3></div>
          <div class="review-write">
            <button
              type="button"
              onclick="showModal('regModal')"
              href="JavaScript:void(0);"
            >
              <img src="../assets/review-write.svg" />
            </button>
          </div>
        </div>
        <div class="review-counter">
          <div class="review-01">
            {{ gameDetails.avgRating ? gameDetails.avgRating : "5.0" }}
          </div>
          <Rating :width="gameDetails.rating" />
        </div>
        <div class="dg-rev-02">
          {{ gameDetails.totRating ? gameDetails.totRating : 1 }}
          Ratings
        </div>
      </div>

      <div
        class="reviewee-01"
        v-for="ratingDetail in ratingDetails"
        :key="ratingDetail.id"
      >
        <div class="rvr-profile">
          <div class="profile-rv-imz">
            <img src="../assets/profile-photo-1.png" />
          </div>
          <div class="rvr-des">
            <Rating :width="ratingDetail.ratingInHundred" />
            <h2>
              {{
                ratingDetail.name ? ratingDetail.name : ratingDetail.userNameFR
              }}
            </h2>
            <h3>
              {{
                ratingDetail.regiDate
                  ? ratingDetail.regiDate
                  : ratingDetail.user_registration_date
              }}
            </h3>
            <!--            <h3>Member since 2 years</h3>-->
          </div>
        </div>

        <div class="revr-commets">
          <p>
            {{ ratingDetail.comment }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else-if="!validGame"
      style="color: red;text-align: center;padding: 25px;"
    >
      Invalid Game
    </div>
    <div v-if="iframeOn">
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        :width="width"
        :height="height"
        allowfullscreen="true"
        :src="gameDetails.link"
      >
      </iframe>
    </div>
    <iframe
      v-if="iframeOn2"
      :width="width"
      :height="height"
      :src="videoResources[0].video_path"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
    <div class="modal-background" id="regModal">
      <div class="modal-content">
        <span class="close" onclick="hideModal('regModal')"></span>
        <h6>
          We would love to hear your feedback!
        </h6>

        <div class="start-rv-area-01">
          <form>
            <div class="stars">
              <input
                type="radio"
                class="star-1"
                id="star-1"
                v-model="rating"
                value="1"
              />
              <label class="star-1" for="star-1">1</label>
              <input
                type="radio"
                class="star-2"
                id="star-2"
                v-model="rating"
                value="2"
              />
              <label class="star-2" for="star-2">2</label>
              <input
                type="radio"
                class="star-3"
                id="star-3"
                v-model="rating"
                value="3"
              />
              <label class="star-3" for="star-3">3</label>
              <input
                type="radio"
                class="star-4"
                id="star-4"
                v-model="rating"
                value="4"
              />
              <label class="star-4" for="star-4">4</label>
              <input
                type="radio"
                class="star-5"
                id="star-5"
                v-model="rating"
                value="5"
              />
              <label class="star-5" for="star-5">5</label>
              <span></span>
            </div>
          </form>
        </div>
        <div class="fedback-text-area-01">
          <form id="feedback-3">
            <textarea
              placeholder="Write here"
              id="user-feedback-text"
              v-model="comment"
            >
            </textarea>
          </form>
          <div class="feedback-btn">
            <button @click="review(phone)" :disabled="disabled" id="rating">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-background" id="regModal2">
      <div class="modal-content">
        <span class="close" @click="hideModal('regModal2')"></span>
        <h4>Sorry!</h4>
        <h5>You don't have available credit to play this game</h5>
      </div>
    </div>
    <div class="modal-background" id="loginError">
      <div class="modal-content pop-02">
        <span class="close" @click="hideModal('loginError')"></span>
        <h4>Please login to play game</h4>

        <router-link to="/registration" tag="button" class="submit-btn5"
          >Okay</router-link
        >
        <router-link to="/" tag="button" class="submit-btn6"
          >Cancel</router-link
        >
      </div>
    </div>
    <SocialShare />
  </div>
</template>

<script>
import axios from "axios";
import Rating from "@/components/Rating.vue";
import SocialShare from "@/components/SocialShare.vue";

export default {
  name: "GameDetail",
  components: {
    Rating,
    SocialShare
  },
  data: () => {
    return {
      gameDetails: [],
      ratingDetails: [],
      gameResources: [],
      videoResources: [],
      gameId: 0,
      phone: localStorage.getItem("phone"),
      host: null,
      iframeOn: 0,
      iframeOn2: 0,
      rating: 0,
      comment: null,
      disabled: true,
      bodyContainer: false,
      validGame: true,
      width: 0,
      height: 0,
      options: {
        accessToken: null,
        bare: false,
        counter: false,
        copy: "last",
        description: null,
        direction: "horizontal",
        hashtags: null,
        image: null,
        lang: "ru",
        limit: 24,
        popupDirection: "bottom",
        popupPosition: "inner",
        size: "m",
        title: null,
        url: null,
        services: ["facebook", "twitter", "whatsapp", "skype", "viber"]
      }
    };
  },
  created() {
    this.host = this.$root.url();
    const path = "gameDetails";
    let url = `${this.host}/${path}?gameId=${this.$route.params.gameId}`;
    if (this.phone) {
      url += `&phone=${this.phone}`;
    }
    axios
      .get(url)
      .then(response => {
        if (response.data.success === true) {
          this.gameDetails = response.data.gameDetails;
          this.gameResources = response.data.appResources;
          this.videoResources = response.data.video;
          this.ratingDetails = response.data.ratingDetails;
          this.gameId = this.$route.params.gameId;
          if (this.phone) {
            this.comment = response.data.comment;
            this.rating = response.data.rating;
          }
          this.bodyContainer = true;
        } else {
          this.validGame = false;
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  watch: {
    comment: function(comment) {
      if (comment && this.rating) {
        this.disabled = false;
      }
    },
    rating: function(rating) {
      if (rating && this.comment) {
        this.disabled = false;
      }
    }
  },
  methods: {
    showModal: function(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    hideModal: function(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    startGame: function(gameLink) {
      let vm = this;
      console.log(gameLink);
      $("#startGame").hide();
      if (!this.phone) {
        this.showModal("loginError");
        $("#startGame").show();
        return false;
      } else {
        const path = "userCoinInfo";
        const url = `${this.host}/${path}?phone=${this.phone}&gameId=${this.gameId}`;
        axios
          .get(url)
          .then(response => {
            if (response.data.success === true && response.data.isPlayGame) {
              this.bodyContainer = false;
              this.iframeOn = 1;
              this.width = window.innerWidth;
              this.height = window.innerHeight;
            } else {
              if (response.data.code === 3004) {
                localStorage.clear();
                vm.$router.push({ name: "Registration" });
              }
              vm.showModal("regModal2");
              setTimeout(function() {
                vm.$router.push({ name: "PurchaseCoin" });
              }, 2000);
              $("#startGame").show();
            }
          })
          .catch(error => {
            console.log(error);
            $("#startGame").show();
          });
      }
    },
    async review(phone = null) {
      try {
        $("#rating").hide();
        let comment = this.comment;
        let rating = this.rating;
        const path = "rating";
        let url = "";
        if (phone && this.gameId) {
          url = `${this.host}/${path}?phone=${phone}&gameId=${this.gameId}&comment=${comment}&rating=${rating}`;
        } else {
          alert("please login");
          $("#rating").show();
          return true;
        }
        let response = await axios.get(url);
        let success = response.data.success;
        if (success) {
          this.gameDetails = response.data.gameDetails;
          this.ratingDetails = response.data.ratingDetails;
          $("#rating").show();
          this.hideModal("regModal");
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
          $("#rating").show();
        }
      } catch (e) {
        $("#rating").show();
      }
    },
    video() {
      this.iframeOn2 = 1;
      this.bodyContainer = false;
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      console.log(
        "iframeOn2",
        this.iframeOn2,
        "width",
        this.width,
        "height",
        this.height
      );
    },
    pathName(url) {
      let newUrl = "";
      console.log("url ", url);
      let substring = url.slice(0, 4);
      if (substring === "http") {
        newUrl = url;
      } else {
        newUrl = this.host + url;
      }
      return newUrl;
    }
  }
};
</script>

<style scoped></style>
