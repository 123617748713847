<template>
  <div>
    <div class="content-header">
      <div class="title-1">
        <router-link to="/"
          ><img src="../assets/back_arrow.svg" /><span style="color: #44D62C;"
            >Home</span
          ></router-link
        >
      </div>
    </div>

    <div class="single-games">
      <div class="under-construction">
        <h3><img src="../assets/uc-1.svg" /></h3>
        <h4>This page is under construction!</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkout"
};
</script>

<style scoped></style>
