<template>
  <div class="modal-background" id="shareModal">
    <div class="modal-content-share">
      <span class="close" onclick="hideModal('shareModal')"></span>

      <div class="sharable-contents">
        <h3 style="color: black;font-weight: bold;">Social Share</h3>
        <p style="color: black;font-weight: bold;">
          Share with your friends and family!
        </p>

        <div class="social-icons-all">
          <vue-yandex-share v-bind="options"></vue-yandex-share>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueYandexShare from "@alexlit/vue-yandex-share";
export default {
  name: "SocialShare",
  components: {
    VueYandexShare
  },
  data: () => {
    return {
      path: "/images/",
      options: {
        accessToken: null,
        bare: false,
        counter: false,
        copy: "last",
        description: null,
        direction: "vertical",
        hashtags: null,
        image: null,
        lang: "en",
        limit: 24,
        popupDirection: "bottom",
        popupPosition: "block",
        size: "m",
        title: null,
        url: null,
        services: ["facebook", "twitter", "whatsapp"]
      }
    };
  },
  methods: {
    hideModal() {
      //this.$router.push({ name: this.$props.state });
      $("#payment2").fadeOut(400);
      $("body").toggleClass("no-scroll");
    }
  }
};
</script>

<style scoped></style>
